import { StepNames, STEP_NAMES } from "../../shared";
import { TimePeriod } from "../../shared/interfaces/slot";
import { AnalyticsEventActions } from "./eventActions";
import {
  AnalyticsCategory,
  AnalyticsEvent,
  AnalyticsEventType,
} from "./interfaces";

const getAnalyticsEvent = (event: AnalyticsEvent) => ({ event });

export const onBookHereEvent = (venueName: string) =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.BOOK_HERE_STORE_FINDER,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `Store: ${venueName}`,
    },
  });

export const onJoinQueueEvent = (venueName: string) =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.JOIN_QUEUE_STORE_FINDER,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `Store: ${venueName}`,
    },
  });

export const onPostcodeSearchEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.POSTCODE_STORE_FINDER,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Postcode",
    },
  });

export const onSelectQueueEvent = (queueName: string) =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.SELECT_QUEUE,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `Queue: ${queueName}`,
    },
  });

// Map stepName to pageName used in BwV1 Analytics
const getPageName = (stepName: StepNames) => {
  switch (stepName) {
    case STEP_NAMES.VENUES:
      return "Venue";
    case STEP_NAMES.QUEUES:
      return "Queue";
    case STEP_NAMES.PRODUCTS:
      return "Product";
    case STEP_NAMES.ADDONS:
      return "Add-on";
    case STEP_NAMES.ADVISORS:
      return "Advisor Selection";
    case STEP_NAMES.SLOTS:
      return "Appointment Time";
    case STEP_NAMES.DETAILS:
      return "Customer Details";
    case STEP_NAMES.CONFIRMATION:
      return "Customer Questions";
    default:
      return "";
  }
};

export const onBackButtonEvent = (currentStepName: StepNames) => {
  const pageName = getPageName(currentStepName);

  return getAnalyticsEvent({
    action:
      `${AnalyticsEventActions.BACK_BUTTON} ${pageName}` as AnalyticsEventActions,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `${pageName} ${AnalyticsEventActions.BACK_BUTTON}`,
    },
  });
};

export const onEditButtonEvent = (stepName: StepNames) => {
  const pageName = getPageName(stepName);

  return getAnalyticsEvent({
    action:
      `${AnalyticsEventActions.EDIT_BUTTON} ${pageName}` as AnalyticsEventActions,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `${pageName} ${AnalyticsEventActions.EDIT_BUTTON}`,
    },
  });
};

export const onAdvisorProfilePageButtonConfirmationEvent = () => {
  return getAnalyticsEvent({
    action: AnalyticsEventActions.ADVISOR_PROFILE_PAGE_BUTTON_CONFIRMATION,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Confirmation Advisor Profile Page Button",
    },
  });
};

export const onSelectProductEvent = (productName: string) =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.SELECT_PRODUCT,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `Product: ${productName}`,
    },
  });

export const onSelectAddonEvent = (addonName: string) =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.SELECT_ADDON,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `AddOn: ${addonName}`,
    },
  });

export const onRemoveAddonEvent = (addonName: string) =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.REMOVE_ADDON,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `AddOn: ${addonName}`,
    },
  });

export const onNextButtonAddonEvent = () =>
  getAnalyticsEvent({
    action:
      `${AnalyticsEventActions.NEXT_BUTTON} Add-on` as AnalyticsEventActions,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `Add-on ${AnalyticsEventActions.NEXT_BUTTON}`,
    },
  });

export const onSelectAdvisorEvent = (advisorName: string) =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.SELECT_ADVISOR,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `Advisor: ${advisorName}`,
    },
  });

export const onNoPreferenceButtonAdvisorSelectionEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.NO_PREFERENCE_BUTTON_ADVISOR_SELECTION,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "No Preference Button",
    },
  });

export const onMoreInfoButtonAdvisorSelectionEvent = (advisorName: string) =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.MORE_INFO_BUTTON_ADVISOR_SELECTION,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `More Info Advisor: ${advisorName}`,
    },
  });

export const onBackButtonAdvisorProfileEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.BACK_BUTTON_ADVISOR_PROFILE,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Advisor Profile Back Button",
    },
  });

export const onBookWithButtonAdvisorProfileEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.BOOK_WITH_BUTTON_ADVISOR_PROFILE,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Book With Button",
    },
  });

export const onSelectDayAppointmentTime = (day: string) =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.SELECT_DAY_APPOINTMENT_TIME,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `Day: ${day}`,
    },
  });

export const onNextDayButtonAppointmentTimeEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.NEXT_DAY_BUTTON_APPOINTMENT_TIME,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Appointment Time Next Day Button",
    },
  });

export const onPreviousDayButtonAppointmentTimeEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.PREVIOUS_DAY_BUTTON_APPOINTMENT_TIME,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Appointment Time Previous Day Button",
    },
  });

const getPeriodName = (period: TimePeriod) => {
  switch (period) {
    case TimePeriod.earlyMorning:
      return "Early Morning";
    case TimePeriod.morning:
      return "Morning";
    case TimePeriod.afternoon:
      return "Afternoon";
    case TimePeriod.evening:
      return "Evening";
    default:
      return "";
  }
};

export const onClickSlotTimePeriodEvent = (period: TimePeriod, date: string) =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.CLICK_SLOT,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `${getPeriodName(period)} Slot: ${date}`,
    },
  });

export const onScrollUpSlotEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.SCROLL_UP_SLOT,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Scroll Up",
    },
  });

export const onScrollDownSlotEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.SCROLL_DOWN_SLOT,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Scroll Down",
    },
  });

export const onSelectSlotEvent = (
  period: TimePeriod | undefined,
  date: string
) =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.SELECT_SLOT,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: period
        ? `${getPeriodName(period)} Slot: ${date}`
        : `Slot: ${date}`,
    },
  });

export const onNextButtonAppointmentTimeEvent = () =>
  getAnalyticsEvent({
    action:
      `${AnalyticsEventActions.NEXT_BUTTON} Appointment Time` as AnalyticsEventActions,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: `Appointment Time ${AnalyticsEventActions.NEXT_BUTTON}`,
    },
  });

export const onNextButtonCustomerDetailsEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.NEXT_BUTTON_CUSTOMER_DETAILS,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Customer Details Next Button",
    },
  });

export const onCompleteButtonCustomerDetailsEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.COMPLETE_BUTTON_CUSTOMER_DETAILS,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Customer Details Complete Button",
    },
  });

export const onCompleteButtonCustomerAnswersEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.COMPLETE_BUTTON_CUSTOMER_ANSWERS,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Customer Answers Complete Button",
    },
  });

export const onUpdateButtonCustomerAnswersEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.UPDATE_BUTTON_CUSTOMER_ANSWERS,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Customer Answers Update Button",
    },
  });

export const onRescheduleButtonConfirmationEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.RESCHEDULE_BUTTON_CONFIRMATION,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Confirmation Reschedule Button",
    },
  });

export const onYesButtonRescheduleBookingConfirmationEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.YES_BUTTON_RESCHEDULE_BOOKING,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Reschedule Booking Yes Button",
    },
  });

export const onNoButtonRescheduleBookingConfirmationEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.NO_BUTTON_RESCHEDULE_BOOKING,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Reschedule Booking No Button",
    },
  });

export const onCancelButtonConfirmationEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.CANCEL_BUTTON_CONFIRMATION,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Confirmation Cancel Button",
    },
  });

export const onYesButtonCancelBookingConfirmationEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.YES_BUTTON_CANCEL_BOOKING,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Cancel Booking Yes Button",
    },
  });

export const onNoButtonCancelBookingConfirmationEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.NO_BUTTON_CANCEL_BOOKING,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Cancel Booking No Button",
    },
  });

export const onGoBackToHomepageButtonConfirmationEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.GO_BACK_TO_HOMEPAGE_BUTTON_CONFIRMATION,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Confirmation Back to Homepage Button",
    },
  });

export const onGoBackToHomepageButtonCancelConfirmationEvent = () =>
  getAnalyticsEvent({
    action:
      AnalyticsEventActions.GO_BACK_TO_HOMEPAGE_BUTTON_CANCEL_CONFIRMATION,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Cancel Confirmation Back to Homepage Button",
    },
  });

export const onBookNewAppointmentButtonCancelConfirmationEvent = () =>
  getAnalyticsEvent({
    action:
      AnalyticsEventActions.BOOK_NEW_APPOINTMENT_BUTTON_CANCEL_CONFIRMATION,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Cancel Confirmation Book New Appointment Button",
    },
  });

export const TRACK_ROUTE_CHANGE_COMPLETE_EVENT = false; // This event doesn't need to be tracked
export const onRouteChangeCompleteEvent = (url: string) =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.ROUTE_CHANGE_COMPLETE,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.SCREEN_TRACKING,
      label: url,
    },
  });

export const onServiceCheckboxConfirmationEvent = () =>
  getAnalyticsEvent({
    action: AnalyticsEventActions.PRODUCT_CONFIRMATION_CHECKBOX,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label: "Product Confirmation Checkbox",
    },
  });

export const onCustomerDetailsInputClickEvent = (fieldName: string) => {
  // Transform `firstName` string (camelCase) text to `First Name`
  const result = fieldName.replace(/([A-Z])/g, " $1");
  const label = result.charAt(0).toUpperCase() + result.slice(1);

  return getAnalyticsEvent({
    action: fieldName as AnalyticsEventActions,
    properties: {
      category: AnalyticsCategory.EVENT,
      eventType: AnalyticsEventType.CLICK,
      label,
    },
  });
};
